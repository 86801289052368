<template>
  <div v-if="loaded && isVisible" :style="frameStyle">
    <div :style="circleStyle"></div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  blok: {
    type: Object,
    default: () => ({
      colour: { value: 'rgb(95, 20, 225)' },
      blur: 80,
      opacity: 0.3,
      circleSize: 1005, // Separate size for the circle
      gradientStops: [],
      screenSettings: [
        {
          screenSize: 'Desktop',
          frameTop: '10px',
          frameLeft: '20px',
          frameRight: null,
          frameBottom: null,
          frameWidth: '300px',
          frameHeight: '300px',
          circleTop: '50px',
          circleLeft: '60px',
          circleRight: null,
          circleBottom: null
        },
        // You can add Tablet and Mobile screen settings here
      ],
    }),
  },
});

const screenSize = ref('Desktop');
const loaded = ref(false);

// Function to detect screen size
function updateScreenSize() {
  const width = window.innerWidth;
  if (width < 768) {
    screenSize.value = 'Mobile';
  } else if (width < 1024) {
    screenSize.value = 'Tablet';
  } else {
    screenSize.value = 'Desktop';
  }
}

// Run the updateScreenSize function when component mounts
onMounted(() => {
  updateScreenSize();
  loaded.value = true; // Mark as loaded after initial screen size is determined
  window.addEventListener('resize', updateScreenSize);
});

// Clean up the event listener
onBeforeUnmount(() => {
  window.removeEventListener('resize', updateScreenSize);
});

// Function to get frame values with fallback logic
function getFrameValueWithFallback(valueKey) {
  const desktopValue = props.blok.screenSettings.find(setting => setting.screenSize === 'Desktop')?.[valueKey];
  const tabletValue = props.blok.screenSettings.find(setting => setting.screenSize === 'Tablet')?.[valueKey];
  const mobileValue = props.blok.screenSettings.find(setting => setting.screenSize === 'Mobile')?.[valueKey];

  // Prioritize the specific screen size, then fall back to smaller or larger settings
  if (screenSize.value === 'Desktop') {
    return desktopValue ?? (tabletValue !== undefined ? tabletValue : mobileValue) ?? null;
  } else if (screenSize.value === 'Tablet') {
    return tabletValue ?? (mobileValue !== undefined ? mobileValue : desktopValue) ?? null;
  } else { // Assume 'Mobile' as the fallback case
    return mobileValue ?? (tabletValue !== undefined ? tabletValue : desktopValue) ?? null;
  }
}

// Function to get circle values with fallback logic
function getCircleValueWithFallback(valueKey) {
  const desktopValue = props.blok.screenSettings.find(setting => setting.screenSize === 'Desktop')?.[valueKey];
  const tabletValue = props.blok.screenSettings.find(setting => setting.screenSize === 'Tablet')?.[valueKey];
  const mobileValue = props.blok.screenSettings.find(setting => setting.screenSize === 'Mobile')?.[valueKey];

  // Prioritize based on current screen size
  if (screenSize.value === 'Desktop') {
    return desktopValue || tabletValue || mobileValue || null;
  } else if (screenSize.value === 'Tablet') {
    return tabletValue || mobileValue || desktopValue || null;
  } else {
    return mobileValue || tabletValue || desktopValue || null;
  }
}

// Computed properties for frame positions
const frameTop = computed(() => getFrameValueWithFallback('frameTop'));
const frameRight = computed(() => getFrameValueWithFallback('frameRight'));
const frameBottom = computed(() => getFrameValueWithFallback('frameBottom'));
const frameLeft = computed(() => getFrameValueWithFallback('frameLeft'));
const frameWidth = computed(() => getFrameValueWithFallback('frameWidth'));
const frameHeight = computed(() => getFrameValueWithFallback('frameHeight'));

// Computed properties for circle positions
const circleTop = computed(() => getCircleValueWithFallback('circleTop'));
const circleRight = computed(() => getCircleValueWithFallback('circleRight'));
const circleBottom = computed(() => getCircleValueWithFallback('circleBottom'));
const circleLeft = computed(() => getCircleValueWithFallback('circleLeft'));
const circleWidth = computed(() => getCircleValueWithFallback('circleSize'));
const circleHeight = computed(() => getCircleValueWithFallback('circleSize'));

// Computed property for visibility with fallback logic
const isVisible = computed(() => {
  if (!loaded.value) return false; // Prevent rendering until the screen size is determined

  const desktopHidden = props.blok.screenSettings.find(setting => setting.screenSize === 'Desktop')?.hidden;
  const tabletHidden = props.blok.screenSettings.find(setting => setting.screenSize === 'Tablet')?.hidden;
  const mobileHidden = props.blok.screenSettings.find(setting => setting.screenSize === 'Mobile')?.hidden;

  if (screenSize.value === 'Desktop') {
    return desktopHidden !== true;
  } else if (screenSize.value === 'Tablet') {
    return tabletHidden !== true;
  } else if (screenSize.value === 'Mobile') {
    return mobileHidden !== true;
  }

  return true; // Default to visible if no specific setting
});


// Helper function to convert hex to rgba
function hexToRgba(hex, opacity) {
  let r = 0, g = 0, b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

// Default gradient stops if none provided
const defaultGradientStops = [
  { stop: 0, opacity: 0.4 },
  { stop: 30, opacity: 0.35 },
  { stop: 50, opacity: 0.25 },
  { stop: 70, opacity: 0.15 },
  { stop: 90, opacity: 0.05 },
  { stop: 100, opacity: 0 }
];

// Gradient background style
const gradientStyle = computed(() => {
  const baseColour = props.blok.colour.value || 'rgb(95, 20, 225)';

  // Check if baseColour is hex and convert it to rgba if necessary
  const rgbaBaseColour = baseColour.startsWith('#')
    ? (opacity) => hexToRgba(baseColour, opacity)
    : (opacity) => baseColour.replace('rgb', 'rgba').replace(')', `, ${opacity})`);

  // Use provided gradient stops or fallback to defaults
  const stops = (props.blok.gradientStops.length ? props.blok.gradientStops : defaultGradientStops).map((stop, index) => {
    const opacity = stop.opacity.value !== undefined ? stop.opacity.value : 1;
    const stopPosition = stop.stop.value !== undefined ? stop.stop.value : index * 20;
    return `${rgbaBaseColour(opacity)} ${stopPosition}%`;
  }).join(', ');

  return `radial-gradient(circle, ${stops}, rgba(95, 20, 225, 0) 100%)`;
});

// Styles for frame and circle
const frameStyle = computed(() => ({
  top: frameTop.value,
  right: frameRight.value,
  bottom: frameBottom.value,
  left: frameLeft.value,
  width: frameWidth.value,
  height: frameHeight.value,
  overflow: 'clip',
  position: 'absolute',
}));

const circleStyle = computed(() => ({
  top: circleTop.value,
  right: circleRight.value,
  bottom: circleBottom.value,
  left: circleLeft.value,
  position: 'absolute',
  width: circleWidth.value,
  height: circleHeight.value,
  filter: `blur(${props.blok.blur !== undefined ? props.blok.blur : 80}px)`, // Default blur if none provided
  opacity: props.blok.opacity.value !== undefined ? props.blok.opacity.value : 0.3, // Default global opacity
  background: gradientStyle.value, // Apply the gradient background
  zIndex: 0, // Default z-index
  borderRadius: '50%', // Default border-radius,
}));
</script>
